jQuery(document).ready(function () {
  console.log('js actes ready');
  const post_id = jQuery('.block-acte-container-table').data('postid');
  var id_cat = -1;
  var search_text = '';
  var pagination = 1;
  var year = '';
  var from = '';
  var to = '';
  var catId = -1;

  const ajax_filter = function (
    natureId,
    searchedText,
    pagination,
    year,
    periodFrom,
    periodTo,
    catId
  ) {
    jQuery.ajax({
      url: vernalisActes.ajax_url,
      type: 'POST',
      data: {
        action: 'filter_actes',
        nature_id: natureId,
        cat_id: catId,
        search: searchedText,
        paged: pagination,
        year: year,
        from: periodFrom,
        to: periodTo,
        post_id: post_id,
        nonce: vernalisActes.nonce,
      },
      beforeSend: function () {
        jQuery('.loader-ajax-list-actes').addClass('loader-list-actes-active');
      },
      success: function (data) {
        jQuery('#actes-found-posts').html(data.count_acte);
        jQuery('#dynamic-body-table').replaceWith(data.view);
        jQuery('#list-pagination-actes-dynamic').replaceWith(
          data.pagination_view
        );
        pagination_filter();
        jQuery('.loader-ajax-list-actes').removeClass(
          'loader-list-actes-active'
        );
        check_filter();
        // jQuery('html, body').animate(
        //   {
        //     scrollTop: jQuery('.block-acte__list').offset().top,
        //   },
        //   600
        // );
      },
    });
  };

  const reset_filter = () => {
    jQuery('.button-reset-filter-actes').on('click', function () {
      id_cat = -1;
      search_text = '';
      year = '';
      from = '';
      to = '';
      catId = -1;
      jQuery('#select-year-acte, #select-nature-acte, #select-cat-acte').prop('selectedIndex',0);
      jQuery('#search-acte, #period-from, #period-to').val("")
      run_ajax();
    });
  };

  const check_filter = () => {
    if (
      id_cat != -1 ||
      search_text != '' ||
      year != '' ||
      from != '' ||
      to != '' ||
      catId != -1
    ) {
      jQuery('.button-reset-filter-actes').addClass(
        'button-reset-filter-actes-active'
      );
    } else {
      jQuery('.button-reset-filter-actes').removeClass(
        'button-reset-filter-actes-active'
      );
    }
  };

  const pagination_filter = () => {
    jQuery('.el-pagination-actes')
      .not(jQuery('.dots'))
      .on('click', function () {
        const page_selected = jQuery(this).data('pnumactes');

        if (page_selected != pagination && page_selected != 0) {
          pagination = page_selected;

          run_ajax();
        }
      });
  };

  const filter_nature = function () {
    jQuery('#select-nature-acte').on('change', function() {
      const value_select = jQuery(this).val();
      id_cat = value_select
      pagination = 1
      run_ajax();
    })
  };
  const filter_cat = function () {
    jQuery('#select-cat-acte').on('change', function() {
      const value_select = jQuery(this).val();
      catId = value_select
      pagination = 1
      run_ajax();
    })
  };

  const filter_text = function () {
    jQuery('#search-acte').on('change', function () {
      const value_search = jQuery(this).val();
      search_text = value_search;
      pagination = 1;
      run_ajax();
    });
  };

  const filter_year = function () {
    jQuery('#select-year-acte').on('change', function() {
      const year_selected = jQuery(this).val()
      year = year_selected
      pagination = 1
      run_ajax();
    })
  };

  const filter_period = function () {
    jQuery('#period-from').on('change', function () {
      const value_from = jQuery(this).val();
      from = value_from;
      pagination = 1;
      run_ajax();
    });
    jQuery('#period-to').on('change', function () {
      const value_to = jQuery(this).val();
      to = value_to;
      pagination = 1;
      run_ajax();
    });
  };

  const run_ajax = () => {
    ajax_filter(id_cat, search_text, pagination, year, from, to, catId);
  };

  const display_filters_phone = () => {
    jQuery('.filter-fields').hide();
    jQuery('.filter-fields__container-title').on('click', function () {
      jQuery('.filter-fields').slideToggle();
    });
    jQuery('#period-to, #period-to, #select-year-acte, #search-acte, #select-nature-acte, #select-cat-acte').on('change', function () {
      jQuery('.filter-fields').slideToggle();
    });
  };

  filter_nature();
  filter_text();
  filter_year();
  filter_period();
  pagination_filter();
  filter_cat();
  reset_filter();
  if (window.innerWidth < 1024) {
    display_filters_phone();
  }

  const list_year_dropdown = () => {
    
    jQuery(".session-depliable__title").on("click", function () {
      jQuery(this).toggleClass("active");
      jQuery(this).next(".session-depliable__content").slideToggle().toggleClass('active');
    });
  }

  list_year_dropdown()

});
